// Card
// ---
@card-head-color: @heading-color;
@card-head-background: transparent;
@card-head-font-size: @font-size-lg;
@card-head-font-size-sm: @font-size-base;
@card-head-padding: 16px;
@card-head-padding-sm: @card-head-padding / 2;
@card-head-height: 48px;
@card-head-height-sm: 36px;
@card-inner-head-padding: 12px;
@card-padding-base: 26px;
@card-padding-base-sm: @card-padding-base / 2;
@card-actions-background: @component-background;
@card-actions-li-margin: 12px 0;
@card-skeleton-bg: #cfd8dc;
@card-background: @component-background;
@card-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
  0 5px 12px 4px rgba(0, 0, 0, 0.09);
@card-radius: 10px;
@card-head-tabs-margin-bottom: -17px;
@card-head-extra-color: @text-color;

.ant-card {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.03);
}

.ant-card.is-width {
  .ant-card-body {
    padding: 50px 60px !important;
  }
}