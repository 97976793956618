// Breadcrumb
// ---
@breadcrumb-base-color: @text-color-secondary;
@breadcrumb-last-item-color: @text-color;
@breadcrumb-font-size: @font-size-base;
@breadcrumb-icon-font-size: @font-size-base;
@breadcrumb-link-color: @text-color-secondary;
@breadcrumb-link-color-hover: @primary-5;
@breadcrumb-separator-color: @text-color-secondary;
@breadcrumb-separator-margin: 0 @padding-xs;

.ant-breadcrumb {
    & > span:last-child {
        font-weight: 500;
    }
}