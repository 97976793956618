@maxWidth: 1920px;

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.03);
    padding: 0 !important;

    .headerWrapper {
        width: 100%;
        max-width: @maxWidth;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 24px 0 0;
    }

    .logo {
        width: 237px;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            display: block;
            height: 21px;
            background-size: cover;
            margin: auto;
        }
    }

    .headerMenu {
        flex: 1;

        :global {
            .ant-menu-item {
                height: 64px;
                line-height: 64px;
                padding: 0 16px !important;
                border-width: 3px !important;
                margin: 0 17px 0 0 !important;
            }
        }
    }

    .headerAction {
        font-size: 14px;
        padding: 0 16px;
        height: 64px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .userInfo {
            font-size: 14px;
            :global {
                .ant-space-item {
                    display: flex;
                }
            }
        }

        &:not('.driver-highlighted-element'):hover {
            background: rgba(0, 0, 0, .03);
        }
    }

    .msgBadge {
        :global {
            .ant-badge-count {
                height: 13px;
                padding: 0 5px;
                font-size: 9px;
                line-height: 13px;
                color: #FFFFFF;
                background: #FF4D4F;
                right: -7px;
                top: -2px;
            }
        }
    }
}

.msgItem {
    :global {
        .ant-badge-count {
            height: 13px;
            padding: 0 5px;
            font-size: 9px;
            line-height: 13px;
            color: #FFFFFF;
            background: #FF4D4F;
        }
    }
}
.sdkPlat {
    cursor: pointer;
}
@primary-color: #3388ff;