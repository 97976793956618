.icon-postbar {
    display: inline-block;
    min-width: 14px;
    margin-right: 20px;
    width: 18px;
    height: 18px;
    vertical-align: -4px;
    background: url('./assets/icon-postbar.png') no-repeat center;
    background-size: 100% auto;
}
.icon-account-member-manage {
    display: inline-block;
    min-width: 14px;
    margin-right: 20px;
    width: 18px;
    height: 18px;
    vertical-align: -4px;
    background: url('./assets/icon-account-member-manage.svg') no-repeat center;
    background-size: 100% auto;
}
@primary-color: #3388ff;