// Pagination
// ---
@pagination-item-bg: @component-background;
@pagination-item-size: @height-base;
@pagination-item-size-sm: 24px;
@pagination-font-family: @font-family;
@pagination-font-weight-active: 500;
@pagination-item-bg-active: @component-background;
@pagination-item-link-bg: @component-background;
@pagination-item-disabled-color-active: @white;
@pagination-item-disabled-bg-active: darken(@disabled-bg, 10%);
@pagination-item-input-bg: @component-background;
@pagination-mini-options-size-changer-top: 0px;

.ant-pagination-item-link {
    border: none !important;
}

.ant-pagination-item {
    border: none;
    a {
        color: @text-color;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    a:hover {
        border-color: rgba(51, 136, 255, 0.6);
    }
}

.ant-pagination-item-active {
    background: linear-gradient(to right, #4FA4FF , @primary-color);
    a {
        color: #ffffff !important;
        border: none;
    }
}

.ant-pagination-prev,
.ant-pagination-next{
    button {
        color: rgba(0, 0, 0, 0.65);
    }
}