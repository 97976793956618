@maxWidth: 1920px;

.layoutWrapper {
    min-height: 100vh !important;
    min-width: 1200px;
    background: #fff !important;

    .contentWrapper {
        position: relative;
        padding: 74px 0 0;
    }
}
@primary-color: #3388ff;