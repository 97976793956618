@input-hover-border-color: rgba(0, 0, 0, .35);

.ant-input-textarea-show-count {
    position: relative;

    &:after {
        position: absolute;
        bottom: 2px;
        right: 16px;
    }
}

.ant-input:focus, 
.ant-input-focused,
.ant-input-affix-wrapper:focus, 
.ant-input-affix-wrapper-focused  {
    border-color: #3388ff !important;
}

.ant-form-item-has-error {
    .ant-input, 
    .ant-input-affix-wrapper, 
    .ant-input:hover, 
    .ant-input-affix-wrapper:hover {
        border-color: #ff4d4f;
    }
}

.ant-form-item-has-error {
    .ant-input:focus, 
    .ant-input-affix-wrapper:focus, 
    .ant-input-focused, 
    .ant-input-affix-wrapper-focused {
        border-color: #ff4d4f !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, .2);
    }
}

.ant-input[disabled]:hover,
.ant-input-disabled:hover {
    border-color: #9c9c9c;
}