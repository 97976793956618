// Table
// --
@table-bg: @component-background;
@table-header-bg: #f5f5f5;
@table-header-color: @text-color;
@table-header-sort-bg: @background-color-base;
@table-body-sort-bg: #fafafa;
@table-row-hover-bg: @background-color-light;
@table-selected-row-color: inherit;
@table-selected-row-bg: @primary-1;
@table-body-selected-sort-bg: @table-selected-row-bg;
@table-selected-row-hover-bg: darken(@table-selected-row-bg, 2%);
@table-expanded-row-bg: #fbfbfb;
@table-padding-vertical: 16px;
@table-padding-horizontal: 16px;
@table-padding-vertical-md: @table-padding-vertical * 3 / 4;
@table-padding-horizontal-md: @table-padding-horizontal / 2;
@table-padding-vertical-sm: @table-padding-vertical / 2;
@table-padding-horizontal-sm: @table-padding-horizontal / 2;
@table-border-radius-base: @border-radius-base;
@table-footer-bg: @background-color-light;
@table-footer-color: @heading-color;
@table-header-bg-sm: @table-header-bg;
@table-font-size: @font-size-sm;

// Sorter
// Legacy: `table-header-sort-active-bg` is used for hover not real active
@table-header-sort-active-bg: darken(@table-header-bg, 3%);
// Filter
@table-header-filter-active-bg: darken(@table-header-sort-active-bg, 5%);
@table-filter-btns-bg: inherit;
@table-filter-dropdown-bg: @component-background;
@table-expand-icon-bg: @component-background;
@table-selection-column-width: 60px;
@table-selection-extra-right: 0;
// Sticky
@table-sticky-scroll-bar-bg: fade(#000, 35%);
@table-sticky-scroll-bar-radius: 4px;

.ant-table {
    color: fade(@black, 65%);
    .anticon-question-circle {
        color: fade(@black, 45%);
    }
}