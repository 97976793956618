.searchInput {
    padding: 20px 4px 24px 30px;
    :global {
        .ant-select-selection-search-input {
            border-radius: 4px;
        }
    }
}

.searchResult {
    em {
        color: #ff4d4f;
        background-color: #f85b5e10;
        padding: 2px 4px;
        margin: 0 2px;
    }
}
@primary-color: #3388ff;