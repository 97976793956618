// Tag
// --
.ant-tag {
    background: rgba(0, 0, 0, .06);
    border-radius: 10px;
    color: rgba(0, 0, 0, .65);
    font-size: 12px;
    border: none;
    font-weight: normal;
    padding: 0 10px;

    &.ant-tag-red {
        background: rgba(255, 77, 79, 0.10);
        border-radius: 10px;
        color: #FF4D4F;
        font-size: 12px;
        border: none;
        font-weight: normal;
        padding: 0 10px;
    }

    &.ant-tag-blue {
        background: rgba(51, 136, 255, 0.10);
        border-radius: 10px;
        color: #3388ff;
        font-size: 12px;
        border: none;
        font-weight: normal;
        padding: 0 10px;
    }

    &.ant-tag-green {
        background: #DBF5C6;
        border-radius: 10px;
        color: #52C41A;
        font-size: 12px;
        border: none;
        font-weight: normal;
        padding: 0 10px;
    }

    &.ant-tag-yellow {
        background: #FFE8CC;
        border-radius: 10px;
        color: #E29225;
        font-size: 12px;
        border: none;
        font-weight: normal;
        padding: 0 10px;
    }
}