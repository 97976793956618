// Tabs
// ---
@tabs-card-head-background:  rgba(0,0,0,0.06);
@tabs-card-height: 40px;
@tabs-card-active-color: @primary-color;
@tabs-card-horizontal-padding: (@tabs-card-height - floor(@font-size-base * @line-height-base)) / 2 -
  @border-width-base @padding-md;
@tabs-card-horizontal-padding-sm: 6px @padding-md;
@tabs-card-horizontal-padding-lg: 7px @padding-md 6px;
@tabs-title-font-size: 14px;
@tabs-ink-bar-color: @primary-color;
@tabs-bar-margin: 0 0 0 0;
@tabs-horizontal-margin: 0 32px 0 0;
@tabs-horizontal-margin-rtl: 0 0 0 32px;
@tabs-horizontal-padding: @padding-sm 0;
@tabs-horizontal-padding-lg: @padding-md 0;
@tabs-horizontal-padding-sm: @padding-xs 0;
@tabs-vertical-padding: @padding-xs @padding-lg;
@tabs-vertical-margin: 0 0 @margin-md 0;
@tabs-scrolling-size: 32px;
@tabs-highlight-color: @primary-color;
@tabs-hover-color: @primary-5;
@tabs-active-color: @primary-7;
@tabs-card-gutter: 10px;
@tabs-card-tab-active-border-top: none;

.ant-tabs-card  {
    & > .ant-tabs-nav::before,
    & > div > .ant-tabs-nav::before {
        display: none;
    }

    & > .ant-tabs-content-holder {
        .ant-card {
            border-top-left-radius: 0 !important;
        }
    }

    & > .ant-tabs-nav {
        .ant-tabs-tab {
            border: none;
            border-radius: 4px 4px 0 0 !important;
        }
    }
}