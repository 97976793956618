@input-number-hover-border-color: fade(@black, 35%);

.ant-input-number:focus, 
.ant-input-number-focused,
.ant-input-number-affix-wrapper:focus, 
.ant-input-number-affix-wrapper-focused  {
    border-color: #3388ff !important;
}

.ant-form-item-has-error {
    .ant-input-number, 
    .ant-input-number-affix-wrapper, 
    .ant-input-number:hover, 
    .ant-input-number-affix-wrapper:hover {
        border-color: #ff4d4f;
    }
}

.ant-form-item-has-error {
    .ant-input-number:focus, 
    .ant-input-number-affix-wrapper:focus, 
    .ant-input-number-focused, 
    .ant-input-number-affix-wrapper-focused {
        border-color: #ff4d4f !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, .2);
    }
}

.ant-input-number[disabled]:hover,
.ant-input-number-disabled:hover {
    border-color: #9c9c9c;
}