.popover {
    // left: 38px !important;
    top: 168px !important;
    border-radius: 7px;
    position: fixed !important;
    :global {
        .ant-popover-inner-content {
            padding: 17px 0;
            overflow: hidden;
        }
        .ant-btn-primary {
            display: inline-block;
            width: 126px;
            border-radius: 32px;
            margin: 10px auto;
            background: #dbe8fc;
            color: #3388FF;
        }
        .ant-popover-arrow {
            display: none;
        }
    }
    .search {
        width: 90%;
        margin-left: 5%;
        margin-bottom: 20px;
        position: absolute;
        background: #fff;
        z-index: 1;
    }
    .gameList {
        width: 348px;
        height: 434px;
        box-sizing: border-box;
        overflow: scroll;
        margin-top: 52px;
        .gameItem {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 25px;
            cursor: pointer;
            &:hover {
                background-color: #eaf3ff;
            }
        }
        .active {
            background-color: #eaf3ff;
        }
        .gameNameBox {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #232627;
            font-weight: 500;
            .gameName {
                display: inline-block;
                max-width: 160px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        .mode {
            font-size: 12px;
            color: #a9afb9;
        }
        :global {
            .ant-badge-status-text {
                color: #777777;
            }
            .ant-image {
                margin-right: 9px;
            }
            .ant-input-group-addon {
                left: 0 !important;
            }

            .ant-btn-link, .ant-btn-link:enabled {
                background: none !important;
                padding: 0;
                font-size: 12px;
                &:hover,
                &:focus {
                    background: none;
                }
            }
        }
    }
}

.circle {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    margin: 10px auto;
    cursor: pointer;
}

.rect {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 38px;
    line-height: 38px;
    border-radius: 38px;
    margin: 0px auto 10px;
    background: #dbe8fc;
    color: #3388FF;
    cursor: pointer;
    .gameName {
        display: inline-block;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }
}
@primary-color: #3388ff;