.ant-descriptions-item-label {
    color: rgba(0, 0, 0, .7) !important;
}

.ant-descriptions-item-content {
    color: rgba(0, 0, 0, .95) !important;
}

.ant-descriptions-title {
    color: rgba(0, 0, 0, .95) !important;
}