// Steps
// ---
@process-tail-color: @border-color-split;
@steps-nav-arrow-color: fade(@black, 15%);
@steps-background: @component-background;
@steps-icon-size: 28px;
@steps-icon-custom-size: @steps-icon-size;
@steps-icon-custom-top: 0px;
@steps-icon-custom-font-size: 28px;
@steps-icon-top: -1px;
@steps-icon-font-size: @font-size-lg;
@steps-icon-margin: 0 13px 0 0;
@steps-icon-color: fade(@black, 45%);
@steps-title-line-height: @steps-icon-size;
@steps-small-icon-size: 24px;
@steps-small-icon-margin: 0 8px 0 0;
@steps-dot-size: 8px;
@steps-dot-top: 2px;
@steps-current-dot-size: 10px;
@steps-desciption-max-width: 140px;
@steps-nav-content-max-width: auto;
@steps-vertical-icon-width: 16px;
@steps-vertical-tail-width: 16px;
@steps-vertical-tail-width-sm: 12px;

.ant-steps-item-finish,
.ant-steps-item-wait {
    & > .ant-steps-item-container {
        & > .ant-steps-item-content {
            & > .ant-steps-item-title {
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
}

.ant-steps-item-finish .ant-steps-item-icon {
    & > .ant-steps-icon {
        svg {
            width: 12px;
        }
    }
}

.ant-steps-item-wait .ant-steps-item-icon {
    border-color: rgba(0, 0, 0, 0.15);

    & > .ant-steps-icon {
        color: rgba(0, 0, 0, 0.45);
    }
}

.ant-steps-item-process {
    & > .ant-steps-item-container {
        & > .ant-steps-item-content {
            & > .ant-steps-item-title {
                font-weight: 500;
            }
        }
    }
}