// Alert
// ---
@alert-success-color: #52C41A;
@alert-success-border-color: @alert-success-color;
@alert-success-bg-color: rgba(82, 196, 26, .05);
@alert-success-icon-color: @alert-success-color;
@alert-info-color: #3388FF;
@alert-info-border-color: @alert-info-color;
@alert-info-bg-color: rgba(51, 136, 255, .05);
@alert-info-icon-color: @alert-info-color;
@alert-warning-color: #FF8545;
@alert-warning-border-color: @alert-warning-color;
@alert-warning-bg-color: rgba(255, 133, 69, .05);
@alert-warning-icon-color: @alert-warning-color;
@alert-error-color: #FF4D4F;
@alert-error-border-color: @alert-error-color;
@alert-error-bg-color: rgba(255, 77, 79, .05);
@alert-error-icon-color: @alert-error-color;
@alert-message-color: @heading-color;
@alert-text-color: @text-color;
@alert-close-color: @text-color-secondary;
@alert-close-hover-color: @icon-color-hover;
@alert-no-icon-padding-vertical: @padding-xs;
@alert-with-description-no-icon-padding-vertical: @padding-md - 1px;
@alert-with-description-padding-vertical: @padding-md - 6px;
@alert-with-description-padding: 15px 20px;
@alert-icon-top: 8px + @font-size-base * @line-height-base / 2 - @font-size-base / 2;
@alert-with-description-icon-size: 17px;
@alert-with-description-icon-top: @alert-with-description-padding-vertical;

.ant-alert {
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ant-alert-with-description {
  .ant-alert-message {
    font-size: 14px;
    margin: 0;
  }
  .ant-alert-icon {
    position: absolute;
    top: 19px;
    margin-top: -8.5px;
  }
}

.ant-alert-content {
  margin-left: 30px;
}

.ant-alert-error {
  .ant-alert-message,
  .ant-alert-description {
    font-weight: 500;
    color: @alert-error-border-color;
  }
}

.ant-alert-success {
  .ant-alert-message,
  .ant-alert-description {
    font-weight: 500;
    color: @alert-success-border-color;
  }
}

.ant-alert-warning {
  .ant-alert-message,
  .ant-alert-description {
    font-weight: 500;
    color: @alert-warning-border-color;
  }
}