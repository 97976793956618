@maxWidth: 1920px;

.layoutWrapper {
    min-height: 100vh !important;

    .mainWrapper {
        width: 100%;
        max-width: @maxWidth;
        margin: auto;
        position: relative;
        z-index: 98;

        &.collapsed {
            .contentWrapper {
                padding-left: 72px;
            }

            .sider {
                :global {
                    .ant-menu-submenu-title {
                        padding: 0 16px !important;
                    }
                }
            }
        }

        .sider {
            position: fixed;
            top: 0;
            flex: 0 0 240px !important;
            width: 240px !important;
            max-width: 240px !important;
            height: 100vh;
            padding-top: 76px;
            z-index: 99;
            overflow: auto;
            background: transparent;
            box-shadow: none !important;

            :global {
                .ant-layout-sider-children {
                    overflow-x: hidden;
                }

                .ant-menu {
                    color: #3D3D3D;
                    background: transparent;
                    border: none;
                    padding: 0 6px;
                }

                .ant-menu-item a {
                    color: #5F5F5F;
                }
                .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
                    color: #3388ff;
                }

                .ant-menu-submenu {
                    margin-bottom: 20px;
                }

                .ant-menu-submenu-title {
                    font-size: 16px;
                    margin-bottom: 4px;
                    font-weight: 500;
                }

                .ant-menu-submenu-selected .ant-menu-submenu-arrow::before,
                .ant-menu-submenu-selected .ant-menu-submenu-arrow::after {
                    background: linear-gradient(to right, #3388ff, #3388ff);
                }

                .ant-menu-item {
                    padding-left: 34px !important;
                    line-height: 28px;
                    height: 28px;
                    color: #5f5f5f;

                    &::after {
                        display: none;
                    }
                    &::before {
                        width: 5px;
                        height: 5px;
                        background: #7F7F7F;
                        border-radius: 50%;
                        content: '';
                        display: block;
                        position: absolute;
                        left: 18px;
                        top: 12px;
                    }
                }

                .ant-menu-item-selected {
                    background: transparent;
                    &::before {
                        background: #3388ff;
                    }
                }

                .ant-menu-root > .ant-menu-submenu:nth-last-child(1) {
                    margin-bottom: 48px;
                }
            }

        }

        .contentWrapper {
            position: relative;
            padding: 94px 24px 28px 260px;
            overflow: auto !important;

            article {
                font-size: 14px;
                color: #5F5F5F;
                letter-spacing: 0;
                line-height: 26px;

                h1 {
                    font-size: 30px;
                    color: #181818;
                    letter-spacing: 0;
                    line-height: 36px;
                }

                h2 {
                    font-size: 22px;
                    color: #181818;
                    letter-spacing: 0;
                    line-height: 22px;
                    margin-bottom: 17px;
                    margin-top: 50px;
                }

                strong {
                    font-weight: 500;
                    color: #0c0c0c;
                }

                table {
                    width: 100%;
                    margin: 1.33em 0;
                    th {
                        color: #3d3d3d;
                        font-weight: 500;
                        text-align: left;
                        background: #f0f0f0;
                        border-bottom: 1px solid #f0f0f0;
                        padding: 16px;
                    }
                    td {
                        padding: 16px;
                        border-bottom: 1px solid #f0f0f0;
                    }
                }

                :global {
                    .ant-typography-danger {
                        color: #FF4D4F;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
@primary-color: #3388ff;