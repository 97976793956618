@maxWidth: 1920px;
.layoutWrapper {
    min-height: 100vh !important;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        box-shadow: 0 3px 3px 0 rgba(0,0,0,0.03);
        padding: 0;

        .headerWrapper {
            width: 100%;
            max-width: @maxWidth;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            padding: 0 24px 0 0;
        }

        .logo {
            width: 237px;
            display: flex;
            align-items: center;

            img {
                display: block;
                height: 21px;
                background-size: cover;
                margin: auto;
            }
        }

        .headerMenu {
            flex: 1;

            :global {
                .ant-menu-item {
                    height: 64px;
                    line-height: 64px;
                    padding: 0 16px !important;
                    border-width: 3px !important;
                }
            }
        }
    }

    .mainWrapper {
        width: 100%;
        max-width: @maxWidth;
        margin: auto;
        position: relative;

        // :global {
            // .ant-layout-sider.driver-fix-stacking {
                // position: relative !important;
                // padding-top: 12px !important;
                // &+.ant-layout-content {
                    // padding-left: 30px;
                // }
            // }
        // }

        &.collapsed {
            .contentWrapper {
                padding-left: 72px;
            }

            .sider {
                :global {
                    .ant-menu-inline-collapsed {
                        .ant-menu-item {
                            padding-left: calc(50% - 16px / 2) !important;
                        }
                        .ant-menu-submenu {
                            padding-left: 0px;
                        }
                    }
                    .ant-menu-submenu-title {
                        padding: 0 14px !important;
                    }
                    .ant-menu-vertical {
                        border: 1px solid #fff;
                    }
                }
            }
        }

        .sider {
            position: fixed;
            top: 0;
            height: 100vh;
            padding-top: 76px;
            // z-index: 99;
            // box-shadow: 0 4px 5px 0 rgba(0,0,0,0.06);
            overflow-y: auto;
            overflow-x: hidden;
            text-align: center;
            background: transparent !important;
            box-shadow: none;
            z-index: 10;
            &::-webkit-scrollbar {
                width: 0 !important;
            }
            :global {
                .ant-menu {
                    color: #7F7F7F;
                }

                .ant-menu-item {
                    padding-left: 33px !important;

                    a {
                        color: #7f7f7f;
                    }

                    &::after {
                        right: 1px;
                    }
                }
                .ant-menu-submenu {
                    padding-left: 9px;
                    .ant-menu-item {
                        padding-left: 60px !important;
                    }
                }
                .ant-menu-item-selected {
                    display: flex;
                    align-items: center;
                    background: transparent !important;
                    &:after {
                        display: none;
                    }
                }

                .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
                    color: #3388ff;
                }

                .ant-menu-root {
                    border: none;
                    background: transparent;
                }

                .ant-menu-root > .ant-menu-submenu:nth-last-child(1) {
                    margin-bottom: 48px;
                }

                .ant-menu-submenu-title {
                    padding: 0 23px !important;
                    display: flex;
                    align-items: center;
                }

                .ant-menu-inline {
                    border: none;
                }

                .ant-menu-inline-collapsed {
                    width: 48px !important;
                }
                .ant-menu-sub {
                    background: transparent !important;
                }
            }

            .trigger {
                padding-right: 14px;
                border-top: 1px solid #e5e5e5;
                text-align: right;
            }

            .commonBtn {
                display: inline-block;
                height: 38px;
                border-radius: 38px;
                margin: 10px auto 12px;
            }
            .backBtn {
                width: 150px;
                border: 1px solid #3388FF;
                background: transparent;
                color: #3388FF;
            }
        }

        .contentWrapper {
            position: relative;
            padding: 94px 24px 28px 230px;
            overflow: auto !important;

            :global {
                .ant-descriptions-item-container .ant-descriptions-item-label,
                .ant-descriptions-item-container .ant-descriptions-item-content {
                    display: block;
                }
            }
        }
    }
    .aHover {
        &:hover {
            color: #3388FF !important;
        }

        .menuIcon {
            margin-left: 2px;
            display: inline-block;
            height: 14px;
            width: 37px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            :global {
                .ant-image {
                    position: absolute;
                }
            }
        }
    }
}
:global {
    .driverGameHome {
        &.special {
            width: 170px !important;
            left: 20px !important;
        }
        
        .universalGameManage {
            height: 40px;
            padding-left: 12px;
            top: -10px;
            display: block;
            padding: 20px 2px 20px 14px;
            width: 150px;
            display: inline-block;

            .ant-menu-submenu-title {
                padding-left: 0 !important;
            }
            .ant-menu-submenu-arrow {
                right: 5px;
            }
        }
    }

    .driverHome {
        #createGameBtn {
            height: 38px;
            border-radius: 38px;
            margin: 0 10px !important;
        }
    }
}
@primary-color: #3388ff;