// Form
// ---
@label-required-color: @highlight-color;
@label-color: @heading-color;
@form-warning-input-bg: @component-background;
@form-item-margin-bottom: 24px;
@form-item-trailing-colon: true;
@form-vertical-label-padding: 0 0 8px;
@form-vertical-label-margin: 0;
@form-item-label-font-size: @font-size-base;
@form-item-label-height: auto;
@form-item-label-colon-margin-right: 8px;
@form-item-label-colon-margin-left: 2px;
@form-error-input-bg: @component-background;

.ant-form-item-extra {
    font-size: 12px !important;
    margin-top: 4px !important;
    color: rgba(0, 0, 0, .55) !important;
}

.ant-form-item-has-error {
    .tagInput {
        border-color: #ff4d4f;
    }
}

.ant-form-item-label {
    color: rgba(0, 0, 0, .7) !important; 

    label {
        color: rgba(0, 0, 0, .7) !important;  
        min-height: @input-height-base; 
    }
}

.ant-form-item-control {
    color: rgba(0, 0, 0, .95) !important;
    
    label {
        color: rgba(0, 0, 0, .95) !important;   
    }
}