// Menu
// ---
@menu-inline-toplevel-item-height: 42px;
@menu-item-height: 36px;
@menu-item-group-height: @line-height-base;
@menu-collapsed-width: 80px;
@menu-bg: @component-background;
@menu-popup-bg: @component-background;
@menu-item-color: @text-color;
@menu-highlight-color: @primary-color;
@menu-highlight-danger-color: @error-color;
@menu-item-active-bg: rgba(51,136,255,0.10);
@menu-item-active-danger-bg: @red-1;
@menu-item-active-border-width: 3px;
@menu-item-group-title-color: @text-color-secondary;
@menu-item-vertical-margin: 0;
@menu-item-font-size: 14px;
@menu-item-boundary-margin: 4px;
@menu-item-padding: 0 20px;
@menu-horizontal-line-height: 46px;
@menu-icon-margin-right: 20px;
@menu-icon-size: @menu-item-font-size;
@menu-icon-size-lg: @font-size-lg;
@menu-item-group-title-font-size: @menu-item-font-size;

.ant-menu-submenu {
    .ant-menu-submenu-arrow {
        color: #7f7f7f;
    }
}

.ant-menu-submenu-selected {
    .ant-menu-submenu-title,
    .ant-menu-item-selected {
        font-weight: 500;
    }

    .ant-menu-submenu-arrow {
        color: #3388ff;
    }
}

.ant-menu-sub.ant-menu-inline {
    background: transparent !important;
}