@text-color: #fff;
@popover-bg: #ffffff;
@stage-bg: #ffffff;
@button-bg: #f1f1f1;
@disabled-btn-color: #808080;

@popover-z-index: 1000000000;
@overlay-z-index: 100002;
@stage-z-index: 100003;
@highlighted-element-z-index: 100004;

// Note: If you update this duration, make sure to
// update `ANIMATION_DURATION_MS` constant
@animation-ms: 300;
@animation-sec: (@animation-ms / 1000) * 1s;

div#driver-popover-item {
  display: none;
  position: absolute;
  color: @text-color;
  margin: 0;
  padding: 21px 22px 21px 77px;
  border-radius: 5px;
  background-image: linear-gradient(270deg, #3388FF 0%, #6AB2FF 100%);
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.06), 0 1px 10px 0 rgba(0,0,0,0.05), 0 2px 4px -1px rgba(0,0,0,0.04);
  z-index: @popover-z-index;
  margin-right: 20px;
  margin-left: 60px;

  &::after {
    content: '';
    display: block;
    width: auto;
    width: 113px;
    height: 87px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -20%);
  }

  .driver-popover-container {
      display: flex;
      align-items: center;
  }

  .driver-popover-tip {
    width: 18px;
    height: 60px;
    background: url('../assets/imgs/arrow.png') no-repeat;
    content: '';
    position: absolute;

    &.bottom {
      bottom: -10px;
      border-top-color: @popover-bg;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;

      &.position-center {
        left: 49%;
      }

      &.position-right {
        right: 20px;
      }
    }

    &.left {
      left: -10px;
      top: 10px;
      border-top-color: transparent;
      border-right-color: @popover-bg;
      border-bottom-color: transparent;
      border-left-color: transparent;

      &.position-center {
        top: 46%;
      }

      &.position-bottom {
        top: auto;
        bottom: 20px;
      }
    }

    &.right {
      right: -10px;
      top: 10px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: @popover-bg;

      &.position-center {
        top: 46%;
      }

      &.position-bottom {
        top: auto;
        bottom: 20px;
      }
    }

    &.top {
      top: -60px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: @popover-bg;
      border-left-color: transparent;

      &.position-center {
        left: 49%;
      }

      &.position-right {
        right: 20px;
      }
    }

    &.mid-center {
      display: none;
    }
  }

  .driver-popover-footer {
    display: block;

    button {
      display: inline-block;
      padding: 7px 16px 9px;
      text-decoration: none;
      cursor: pointer;
      outline: none;
      border: 1px solid #FFFFFF;
      border-radius: 17px;
      background: transparent;
      font-size: 14px;
      line-height: 16px;
      height: 32px;
      color: #fff;
      font-weight: 500;
    }

    button.driver-disabled {
      color: @disabled-btn-color;
      cursor: default;
      pointer-events: none;
    }

    .driver-prev-btn {
      display: none !important;
    }

    .driver-next-btn {
      min-width: 130px;
    }

    .driver-close-step-btn {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      padding: 0 0 4px 0;
      background: #fff;
      border: none;
      color: #3388FF;
      font-size: 24px;
      text-align: center;
    }

    .driver-close-only-btn {
      display: block;
    }
  }

  .driver-popover-content {
    margin-right: 20px;
  }

  .driver-popover-title {
    font-size: 22px;
    line-height: 1;
    margin: 0 0 10px;
    font-weight: 500;
  }

  .driver-popover-description {
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
  }
}

.game {
  .top {
    left: 100px!important;
  }
  &::after {
    background: url('../assets/imgs/popGame.png') no-repeat;
  }
}
.doc {
  .top {
    left: 185px!important;
  }
  &::after {
    background: url('../assets/imgs/popDoc.png') no-repeat;
  }
}
.editUser {
  padding: 21px 22px 21px 47px !important;
  width: 450px;
  .top {
    left: 380px!important;
  }
  .driver-popover-title {
    display: none;
  }
  &::after {
    height: 100px !important;
    top: -20px !important;
    background: url('../assets/imgs/popEdit.png') no-repeat;
  }
}
.create {
  left: 0 !important;
  .top {
    left: 30px!important;
  }

  .driver-close-btn{
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px !important;
    padding: 0 0 4px 0 !important;
    background: #fff !important;
    border: none !important;
    color: #3388FF !important;
    font-size: 24px !important;
    text-align: center;
  }
  &::after {
    height: 100px !important;
    top: -20px !important;
    background: url('../assets/imgs/popGame.png') no-repeat;
  }
}
.changeGame {
  left: 0 !important;
  .top {
    left: 30px!important;
  }
  &::after {
    height: 100px !important;
    top: -20px !important;
    background: url('../assets/imgs/popGame.png') no-repeat;
  }
}
.gameBaseInfo {
  left: 0 !important;
  .top {
    left: 30px!important;
  }

  .driver-close-btn{
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px !important;
    padding: 0 0 4px 0 !important;
    background: #fff !important;
    border: none !important;
    color: #3388FF !important;
    font-size: 24px !important;
    text-align: center;
  }
  &::after {
    height: 100px !important;
    top: -20px !important;
    background: url('../assets/imgs/popEdit.png') no-repeat;
  }
}
.gameJoint {
  .top {
    left: 0px!important;
  }
  &+#driver-highlighted-element-stage {
    border-radius: 0;
  }
  &.hide {
    visibility: hidden;
  }
  &::after {
    height: 100px !important;
    top: -10px !important;
    left: 18px !important;
    background: url('../assets/imgs/popJoint.png') no-repeat;
  }
  .driver-popover-tip {
    left: 0;
    transform: rotate(180deg);
    transform-origin: 600% 70%;
    height: 34px !important;
    bottom: -20px !important;
  }
}
.gameBrand {
  .top {
    left: 90px!important;
  }
  &::after {
    height: 100px !important;
    top: -20px !important;
    left: 20px !important;
    background: url('../assets/imgs/popBrand.png') no-repeat;
  }
}

.createGame {
  .top {
    left: 110px!important;
  }
  &::after {
    height: 100px !important;
    top: -20px !important;
    background: url('../assets/imgs/popGame.png') no-repeat;
  }
}
.account {
  top: 321px!important;
  left: 100px!important;

  .top {
    left: 170px!important;
  }

  &::after {
    background: url('../assets/imgs/popAccount.png') no-repeat;
  }
}
.bill {
  top: 460px!important;

  .top {
    left: 70px;
  }

  &::after {
    background: url('../assets/imgs/popBill.png') no-repeat;
  }
}
.withdraw {
  top: 460px!important;

  .top{
    left: 170px!important;
  }

  &::after {
    top: -20px!important;
    height: 100px!important;
    background: url('../assets/imgs/popSearch.png') no-repeat;
  }
}
.asideMenu{
  top: 180px!important;
  padding: 21px 77px 21px 22px!important;
  background-image: linear-gradient(270deg, #6AB2FF 0%, #3388FF 100%)!important;

  &::after {
    top: -10px!important;
    left: 460px!important;
    left: none!important;
    background: url('../assets/imgs/popGameRight.png') no-repeat;
  }

  .top {
    left: 20px!important;
  }
}


.driver-stage-no-animation {
  transition: none !important;

  background: transparent !important;
  outline: 5000px solid rgba(0, 0, 0, 0.75);
  height: 60px!important;
  border-radius: 30px;
}

div#driver-page-overlay {
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  zoom: 1;
  filter: alpha(opacity=75);
  opacity: 0.75;
  z-index: @overlay-z-index !important;

  transition: all @animation-sec;
}

div#driver-highlighted-element-stage {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 300px;
  background: @stage-bg;
  z-index: @stage-z-index !important;
  display: none;
  border-radius: 40px !important;

  transition: all @animation-sec;
  padding-top: 10px;
}

.header {
  height: 56px!important;
  top: 0!important;
  border-radius: 28px!important;
  margin-top: 6px;
}

.gameManage {
  left: 20px!important;
  width: 100px!important;
  height: 38px!important;
  border-radius: 19px!important;

  &::before {
    content: '';
    display: block;
    height: 38px;
    width: 19px;
    border-radius: 19px 0 0 19px;
    top: 0;
    left: -19px;
    background: #fff;
    position: absolute;
  }
  &::after {
    content: '';
    display: block;
    height: 38px;
    width: 19px;
    border-radius: 0 19px 19px 0;
    top: 0;
    left: 100px;
    background: #fff;
    position: absolute;
  }
}

.driver-highlighted-element {
  z-index: @highlighted-element-z-index !important;
}

.driver-position-relative {
  position: relative !important;
}

.driver-fix-stacking {
  z-index: auto !important;
  opacity: 1.0 !important;
  transform: none !important;
  filter: none !important;
  perspective: none !important;
  transform-style: flat !important;
  transform-box: border-box !important;
  will-change: unset !important;

  section {
    // overflow: hidden!important;
    touch-action: none;
    height: 100vh!important;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: @overlay-z-index;
    }
  }

  header {
    position: relative;
  }
  main {
    padding-top: 10px!important;
    .headerWrapperContainer {
      margin-top: 20px;
    }
    .site-card-wrapper, .baiduFinanceTitle {
      margin-top: 20px;
    }
  }
  #button1, #button3, #button4 {
    pointer-events: none;
  }
}

.equityCard, .euqityWrapper, .taskBox {
  padding: 24px !important;
  .bottom {
    transform: rotate(180deg);
    bottom: -60px !important;
  }
  .top {
    transform: rotate(180deg);
    top: 90px !important;
  }
  .driver-popover-container {
    width: 315px;
    flex-direction: column;
    align-items: flex-end !important;
    .driver-popover-footer {
      margin-top: 10px;
      .driver-next-btn {
        font-size: 14px !important;
        padding: 7px 15px 9px !important;
      }
    }
  }
  .driver-popover-description {
    font-size: 14px !important;
    line-height: 1.5 !important;
  }
}

.equityCard {
  top: 140px !important;
  .top {
    top: 142px !important;
  }
}
.euqityWrapper {
  top: 190px !important;
  .top {
    top: 142px !important;
  }
}
.taskBox {
  top: 165px !important;
  .top {
    top: 142px !important;
  }
}


#taskBox {
  border-radius: 6px;
}

.modeBtnDriver {
  &::after {
    height: 100px !important;
    top: -20px !important;
    background: url('../assets/imgs/popGame.png') no-repeat;
  }
  .driver-popover-description {
    line-height: 1.5 !important;
  }
}
.modeBtnBox#driver-highlighted-element-stage {
  border-radius: 20px !important;
}

.driverIndex#driver-highlighted-element-stage {
  top: 8px !important;
  height: 46px !important;
}

@primary-color: #3388ff;