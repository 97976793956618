.popWindow {
    :global {
        .ant-modal-confirm-body{
            margin-top: 20px;
            .ant-modal-confirm-content {
                max-height: 320px;
                overflow: auto;
                padding-right: 32px;
                &::-webkit-scrollbar{
                    width: 0 !important;
                }
            }
        }
    }
}

:global {
    .img-preview-wrap,
    .messagelist-img-preview-wrap {
        .ant-modal-confirm-body {
            max-height: 500px;
            overflow: auto;
            &::-webkit-scrollbar{
                width: 0 !important;
            }
        }
    }
}
        
@primary-color: #3388ff;